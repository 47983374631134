/* 
 * @Author: 曹俊杰 
 * @Date: 2023-01-10 18:00:36
 * @Module: 欢迎页
 */
 <template>
  <div class="box">
    <video src="https://meimengapp.oss-cn-beijing.aliyuncs.com/video/index.mp4" autoplay="autoplay" loop="loop" muted="muted"></video>
    <div class="content">
      <div class="header">
        <!-- <div class="logo"></div> -->
      </div>
      <div class="main">
        <div class="title"></div>
        <div class="ext">多元开放·高效共赢</div>
        <div class="btns">
          <div class="btn" @click="onlink('https://www.oa.mcn-open.com')">
            报单审批
          </div>
          <div class="btn" @click="onlink('https://zhq-profile.mcn-open.com')">
            汇盈E联
          </div>
          <div class="btn" @click="onlink('https://crm.mcn-open.com')">
            线索对接
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
 <script>
export default {
  components: {},
  data () {
    return {};
  },
  mounted () { },
  methods: {
    onlink (href) {
      window.open(href, "_blank");
    }
  },
};
 </script>
 <style lang='scss' scoped>
.box {
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: -1;
  }
  .content {
    width: 1200px;
    height: 100vh;
    z-index: 10;
    margin: 0 auto;
    .header {
      display: flex;
      align-items: center;
      height: 80px;
      .logo {
        width: 135px;
        height: 32px;
        background-image: url(~@/static/wellcome/logo.png);
        background-size: 135px 32px;
      }
    }
    .main {
      width: 710px;
      height: 322px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      @keyframes tit {
        0% {
          transform: scale(0.8);
        }
        100% {
          transform: scale(1);
        }
      }
      .title {
        // background-image: url(~@/static/wellcome/title.png);
        width: 710px;
        height: 126px;
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        animation: tit 5s;
        // display: none;
      }
      .ext {
        font-family: "Source Han Sans CN";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 56px;
        /* identical to box height, or 233% */

        text-align: center;
        letter-spacing: 0.5em;

        color: #ffffff;
        margin-top: 40px;
        opacity: 0;
      }
      .btns {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        .btn {
          width: 128px;
          height: 50px;
          background: rgba(255, 255, 255, 0.01);
          border: 1.5px solid rgba(255, 255, 255, 0.6);
          backdrop-filter: blur(15px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: 4px;
          text-align: center;

          font-family: "Source Han Sans CN";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 50px;
          color: #ffffff;
          cursor: pointer;
          margin-right: 40px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
</style>